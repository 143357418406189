export const environment = {
  production: false,
  api_url: 'https://nssf-ims-api.myplgs.com',
  nssf_auth_url: 'https://accountuat.nssf.gov.kh',
  nssf_redirect_uri: 'https://nssf-ims-web.myplgs.com/auth/callback',
  nssf_logout_redirect_uri: 'https://nssf-ims-web.myplgs.com/login',
  // nssf_redirect_uri: 'http://localhost:4200/auth/callback',
  // nssf_logout_redirect_uri: 'http://localhost:4200/login',
  nssf_investment_client_id: 'f9063d66-7841-1dca-e614-ec9a82b21f78-Invesment',
};
