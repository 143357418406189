import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BreadcrumbItem } from '../../../models/breadcrumb-item';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [TranslateModule, MatIconModule, RouterModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() customItems?: Array<BreadcrumbItem>;
  items: Array<BreadcrumbItem> = [];
  mongoIDReg = /^[a-fA-F0-9]{24}$/;

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.customItems && this.customItems.length > 0) {
      this.items = this.customItems;
    } else {
      let routes: string[];
      const currentRoute = this._router.url.replace('/', '');

      const isSub: boolean = this._route.snapshot.data.isSub;
      const routeType = this._route.snapshot.data.type;
      if (currentRoute.includes('?')) {
        routes = currentRoute.split('?')[0].split('/');
      } else {
        routes = currentRoute.split('/');
      }

      if (isSub) {
        routes = routes.filter((r) => r !== this._route.snapshot?.data?.type);
      }

      let previousPath: string = '';
      for (let index = 0; index < routes.length; index++) {
        if (index - 1 > -1) {
          if (previousPath.trim() === '') {
            previousPath = routes[index - 1];
          } else {
            previousPath = previousPath + '/' + routes[index - 1];
          }
        }
        if (previousPath && previousPath.trim() !== '') {
          if (this.mongoIDReg.test(routes[index])) {
            this.items.push({
              name: 'routes.detail',
              realUrl: previousPath + '/' + routes[index],
              routeType: routeType,
              isSub: isSub,
            });
          } else {
            this.items.push({
              name: `routes.${routes[index]}`,
              realUrl: previousPath + '/' + routes[index],
              routeType: routeType,
              isSub: isSub,
            });
          }
        } else {
          if (this.mongoIDReg.test(routes[index])) {
            this.items.push({
              name: 'routes.detail',
              realUrl: routes[index],
              routeType: routeType,
              isSub: isSub,
            });
          } else {
            this.items.push({
              name: `routes.${routes[index]}`,
              realUrl: routes[index],
              routeType: routeType,
              isSub: isSub,
            });
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.customItems.currentValue &&
      changes.customItems.currentValue.length > 0
    ) {
      this.items = changes.customItems.currentValue;
    }
  }

  routeClick(route: BreadcrumbItem, idx: number): void {
    if (idx === this.items.length - 1) {
      return;
    }

    if (route.isSub && route.routeType) {
      this._router.navigateByUrl(`/${route.routeType}/${route.realUrl}`, {
        replaceUrl: true,
      });
    } else {
      this._router.navigateByUrl(route.realUrl, {
        replaceUrl: true,
      });
    }
  }
}
